import React from 'react'
import { ModalRoutingContext, Link } from 'gatsby-plugin-modal-routing'
import s from './FirstScreen.module.sass'
// 
import {
    Container
} from "../../../ui-kit"
// svg
import Close from '../../../icons/header/close.svg'

const getId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return `//www.youtube.com/embed/${match[2]}`
  } else if (url.includes('facebook.com')) {
    return `https://www.facebook.com/plugins/video.php?href=${url}`
  }
}

const ModalVideo = ({backPath, data, back, errorText}) => {
    const videoId = getId(data.videoLink);

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <>
                    {
                        modal ? (
                            <>
                                <Link to={closeTo} state={{
                                    noScroll: true
                                }} className={s.close}>
                                    <Close/>
                                </Link>
                                <div className={s.videoWrapper}>
                                    {
                                        <iframe className={s.iframe} src={videoId} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={data.title}></iframe>
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <Container>
                                    <br/><br/>
                                    <Link to={backPath}>{back}</Link>
                                    <br/><br/>
                                    <div className={s.videoWrapperPage}>
                                        {
                                            <iframe className={s.iframe} src={videoId} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={data.title}></iframe>
                                        }
                                    </div>
                                </Container>
                            </>
                        )
                    }
                    
                </>
            )}
        </ModalRoutingContext.Consumer>
    )
}

export default ModalVideo