import React from 'react'
import Modal from '../components/Video/FirstScreen/Modal'

const ModalPage = ({data, location}) => {

	let langBase = "/";
	let back = "Вернуться";
	let error = "Видео не доступно";

	if (location.pathname.match("/ua") && location.pathname.match("/ua").index === 0) {
		langBase = "/ua";
		back = "Повернутися";
		error = "Відео недоступне";
	}
	
	const newData = {
    title: data.allDirectusHomeTestimonialsRu.edges[0].node.name,
    videoLink: data.allDirectusHomeTestimonialsRu.edges[0].node.link,
	}
  
  return <Modal backPath={langBase} data={newData} back={back} errorText={error}/>
}

export const query = graphql`
    query($slug: String!) {
        allDirectusHomeTestimonialsRu (filter: {slug: {eq: $slug}}) {
            edges {
              node {
                name
                link
              }
            }
        }
    }
`

export default ModalPage